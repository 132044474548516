* {
  position: relative;
}

html {
  scroll-behavior: smooth;
}

.link-container {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
  text-align: center;
  -webkit-overflow-scrolling: touch;
  /* Enable smooth scrolling on iOS */
}

.link-container a {
  display: inline-block;
  padding: 5px;
  margin-right: 10px;
  text-decoration: none;
  color: #333;
}

.link-container a:hover {
  cursor: pointer;
}

#date {
  font-weight: bold;
}

.loader {
  position: fixed;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: url('https://usagif.com/wp-content/uploads/loading-4.gif') 50% 50% no-repeat rgb(255, 255, 255, 0.5);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

a[disabled] {
  pointer-events: none;
}

.map-icon path {
  fill: #d83d40;
}

.map-icon text {
  fill: white;
  font-size: 10px;
  font-weight: 700;
  line-height: 1;
}

.blog-item .blog-bottom h6 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blog-item .blog-bottom p {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.blog-item .blog-bottom label {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

#hosp_sec p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: black;
  text-overflow: ellipsis;
}

#states small {
  
}

#hosp_sec .department-item:hover p {
  color: white;
}

/* p {
  text-align: justify;
} */

.footer {
  /* position: absolute; */
  bottom: 0;
  line-height: 60px;
  width: 100%;
}

.navbar-brand .logo {
  width: 40%;
}

@media only screen and (max-width: 767px) {
  .banner-area {
    height: 100%;
    padding-top: 70px;
    /* padding-bottom: 150px; */
  }

  .navbar-brand .logo {
    width: 100%;
  }
}